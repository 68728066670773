import React from "react"
import SEO from "../components/seo"
import "./index.css"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>Not Found</h1>
  </>
)

export default NotFoundPage
